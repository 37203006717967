<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>

export default {
  name: "Entity",
  components: {
  },
  data() {
    return {
      parents: [],
    };
  },
  methods: {
    
  },
  computed: {
    
  },
  mounted() {
    
  },
};
</script>
